import { RegisterWallet } from 'components';
import BattlefieldLayout from 'components/Layouts/BattlefieldLayout';
import useWeb3 from 'hooks/useWeb3';
import { useRouter } from 'next/router';
import { SyntheticEvent, useEffect } from 'react';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { getBattleInfo, SignupStep, useGlobalState } from 'state';

import { getStoredBattlefieldUserToken } from '../utils';

const IndexPage = ({ i18n }) => {
	const router = useRouter();
	const [{ web3, user, battlefield }, dispatch] = useGlobalState();
	const { loadModal } = useWeb3({ readOnly: true });
	const { addToast } = useToasts();

	/**
	 * Fetch wallet info once is connected by user
	 */
	useEffect(() => {
		if (web3.accounts.current && user.nextStep === SignupStep.get_info) {
			// Watch
			getBattleInfo(web3.accounts.current)
				.then(dispatch)
				.catch((error) => {
					addToast(error.message, { appearance: "error" });
				});
		}
	}, [web3.accounts.current]);

	useEffect(() => {
		if (
			user.step &&
			user.step !== "signup" &&
			!getStoredBattlefieldUserToken()
		) {
			router.push("/login");
		}

		if (user.step === "signup") {
			router.push("/signup");
		} else if (user.step === "add_martian") {
			router.push("/battlefield/add");
		} else if (user.step === "battlefield") {
			router.push("/battlefield");
		}
	}, [user.step, user.address]);

	const handleRegisterClick = (e: SyntheticEvent) => {
		e.preventDefault();
		loadModal(true);
	};

	return (
		<div
			style={{
				alignItems: "center",
				height: "80vh",
				display: "flex",
				justifyContent: "center",
			}}
		>
			{user.background && (
				<>
					{/* <div
						className="fixed w-full h-full"
						style={{
							backgroundImage: `url('/static/images/backgrounds/5.png')`,
							backgroundSize: "cover",
							backgroundPosition: "center",
							zIndex: -2,
							width: "100%",
							top: 0,
						}}
					/> */}
					<div
						className="fixed w-full h-full top-0 right-0 left-0 bottom-0"
						style={{ zIndex: -2, overflow: 'hidden'}}
					>
						<video style={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							minWidth: '100%',
							maxWidth: '100%',
							width: '100%',
							height: 'auto',
							transform: 'translate(-50%,-50%)',
							zIndex: -2
						}} muted loop autoPlay>
							<source src="https://battle.marsgenesis.com/s3/video/battleofmars.mp4" />
						</video>
					</div>
					<div
						className="fixed w-full h-full bg-black bg-opacity-60"
						style={{ zIndex: -1, height: "110%" }}
					/>
				</>
			)}
			{!user.address && !web3.accounts.current && (
				<RegisterWallet i18n={i18n} onClick={handleRegisterClick} />
			)}
		</div>
	);
};

export const getServerSideProps = async () => {
	const i18n = await require("public/static/i18n/en/battlefield.json");

	return {
		props: {
			i18n,
		},
	};
};

IndexPage.getCustomLayout = function getCustomLayout(Component, pageProps) {
	return <BattlefieldLayout Component={Component} pageProps={pageProps} />;
};

export default IndexPage;
